export const sailyWidgetUrls = [
  {
    destination: "Albania",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "albania",
  },
  {
    destination: "Argentina",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "argentina",
  },
  {
    destination: "Armenia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "armenia",
  },
  {
    destination: "Australia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "australia",
  },
  {
    destination: "Austria",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "austria",
  },
  {
    destination: "Belgium",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "belgium",
  },
  {
    destination: "Belize",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "belize",
  },
  {
    destination: "Brazil",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "brazil",
  },
  {
    destination: "Bulgaria",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "bulgaria",
  },
  {
    destination: "Cameroon",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "cameroon",
  },
  {
    destination: "Cambodia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "cambodia",
  },
  {
    destination: "Canada",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1357",
    slug: "canada",
  },
  {
    destination: "Chile",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "chile",
  },
  {
    destination: "China",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "china",
  },
  {
    destination: "Colombia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "colombia",
  },
  {
    destination: "Costa Rica",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1354",
    slug: "costa-rica",
  },
  {
    destination: "Croatia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "croatia",
  },
  {
    destination: "Denmark",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "denmark",
  },
  {
    destination: "Ecuador",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "ecuador",
  },
  {
    destination: "Egypt",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "egypt",
  },
  {
    destination: "England",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "england",
  },
  {
    destination: "Fiji",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "fiji",
  },
  {
    destination: "France",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1350",
    slug: "france",
  },
  {
    destination: "Georgia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "georgia",
  },
  {
    destination: "Germany",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "germany",
  },
  {
    destination: "Greece",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "greece",
  },
  {
    destination: "Guatemala",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "guatemala",
  },
  {
    destination: "Hungary",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "hungary",
  },
  {
    destination: "Iceland",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "iceland",
  },
  {
    destination: "India",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "india",
  },
  {
    destination: "Indonesia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "indonesia",
  },
  {
    destination: "Ireland",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "ireland",
  },
  {
    destination: "Italy",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1346",
    slug: "italy",
  },
  {
    destination: "Japan",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1348",
    slug: "japan",
  },
  {
    destination: "Jordan",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "jordan",
  },
  {
    destination: "Kazakhstan",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "kazakhstan",
  },
  {
    destination: "Kenya",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "kenya",
  },
  {
    destination: "Lithuania",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "lithuania",
  },
  {
    destination: "Malaysia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "malaysia",
  },
  {
    destination: "Malta",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "malta",
  },
  {
    destination: "Mexico",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "mexico",
  },
  {
    destination: "Mongolia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "mongolia",
  },
  {
    destination: "Montenegro",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "montenegro",
  },
  {
    destination: "Morocco",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1349",
    slug: "morocco",
  },
  {
    destination: "Netherlands",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "netherlands",
  },
  {
    destination: "New Zealand",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "new-zealand",
  },
  {
    destination: "Norway",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "norway",
  },
  {
    destination: "Oman",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "oman",
  },
  {
    destination: "Panama",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "panama",
  },
  {
    destination: "Paraguay",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "paraguay",
  },
  {
    destination: "Peru",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "peru",
  },
  {
    destination: "Philippines",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "philippines",
  },
  {
    destination: "Poland",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "poland",
  },
  {
    destination: "Portugal",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "portugal",
  },
  {
    destination: "Puerto Rico",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "puerto-rico",
  },
  {
    destination: "Romania",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "romania",
  },
  {
    destination: "Singapore",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "singapore",
  },
  {
    destination: "Slovakia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "slovakia",
  },
  {
    destination: "Slovenia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1351",
    slug: "slovenia",
  },
  {
    destination: "South Africa",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "south-africa",
  },
  {
    destination: "South Korea",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "south-korea",
  },
  {
    destination: "Spain",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "spain",
  },
  {
    destination: "Sri Lanka",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1355",
    slug: "sri-lanka",
  },
  {
    destination: "Sweden",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "sweden",
  },
  {
    destination: "Switzerland",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1353",
    slug: "switzerland",
  },
  {
    destination: "Taiwan",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "taiwan",
  },
  {
    destination: "Thailand",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1347",
    slug: "thailand",
  },
  {
    destination: "Tunisia",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "tunisia",
  },
  {
    destination: "Turkey",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "turkey",
  },
  {
    destination: "United Arab Emirates",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "united-arab-emirates",
  },
  {
    destination: "USA",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1356",
    slug: "usa",
  },
  {
    destination: "Uzbekistan",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "uzbekistan",
  },
  {
    destination: "Vanuatu",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "vanuatu",
  },
  {
    destination: "Vietnam",
    url: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
    slug: "vietnam",
  },
];
