import PropTypes from "prop-types";

import { Eyebrow } from "@pageComponents/destinations/eyebrow";
import { Heading } from "@pageComponents/destinations/heading";

/* Card icons */
import {
  ArrowRight,
  BuildingsFeature,
  CalendarFeature,
  CarFeature,
  DaytripFeature,
  FreeFeature,
  KidsFeature,
  LGBTQFeature,
  TravelDocsFeature,
  RailFeature,
  RibbonFeature,
  ShopFeature,
  SignalFeature,
  SpendingDiariesFeature,
  ThingsToKnowFeature,
  TrophyFeature,
  WalletFeature,
  WheelChairFeature,
} from "@icons";

import { keyify } from "@utils/keyify";
import { tagular } from "@cohesion/tagular";

const icons = {
  neighborhoods: BuildingsFeature,
  budget: WalletFeature,
  "day-trips": DaytripFeature,
  "free-things": FreeFeature,
  "getting-around": RailFeature,
  "road-trips-experience": CarFeature,
  "things-to-know": ThingsToKnowFeature,
  "visa-requirements": TravelDocsFeature,
  "with-kids": KidsFeature,
  "things-to-do": RibbonFeature,
  "best-places-to-visit": TrophyFeature,
  "time-to-visit": CalendarFeature,
  "5-shops": ShopFeature,
  "spending-diaries": SpendingDiariesFeature,
  accessibility: WheelChairFeature,
  "lgbtiq-travel": LGBTQFeature,
  connectivity: SignalFeature,
};

export function Planning({ articles }) {
  if (articles.length === 0) return null;

  return (
    <section className="my-16 lg:my-24 mx-4">
      <div className="bg-sand-100 py-16 lg:py-24 rounded-lg">
        <div className="container px-2">
          <div className="flex flex-col items-center">
            <Eyebrow nav="Planning tools">Planning Tools</Eyebrow>
            <Heading className="mt-5 lg:mt-8 mb-10 lg:mb-20 mx-2 lg:mg-0 max-w-4xl text-center">
              Expert guidance to help you plan your trip
            </Heading>
          </div>

          <div>
            <div className="lg:container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 auto-rows-fr">
              {articles.map(article => {
                const Icon = icons[article.tag];
                return (
                  <article
                    key={keyify(article.slug)}
                    className="relative flex flex-col justify-between h-60 md:h-64 xl:h-60 flex-none p-6 border border-black-200 rounded bg-sand"
                  >
                    <div>
                      {Icon && <Icon className="inline-block text-3xl" />}
                      <p className="relative inline-block top-1 left-4 text-xl z-10">
                        {article.copy}
                      </p>
                      <p className="relative my-4 text-sm text-black-400 line-clamp-3x z-10">
                        {article.excerpt}
                      </p>
                      <a
                        href={`/articles/${article.slug}`}
                        className="card-link underline-none"
                        onClick={() => {
                          tagular("click", {
                            actionOutcome: "internallink",
                            outboundUrl: `/articles/${article.slug}`,
                            webElement: {
                              location: "section",
                              position: "planningtoolkit",
                              text: article.copy,
                              elementType: "link",
                            },
                          });
                        }}
                      >
                        <span className="flex items-center">
                          Read article{" "}
                          <ArrowRight className="ml-2 translate-y-[1px] text-sm text-black-300" />
                        </span>
                      </a>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Planning.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      excerpt: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
