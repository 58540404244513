import PropTypes from "prop-types";
import { tagular } from "@cohesion/tagular";

import { SailyLogo } from "@pageComponents/best-in-travel-2025/saily/sailyLogo";

export function Saily({ title, url }) {
  return (
    <div className="border-[#e2edff] border bg-[#F1F6FE] rounded-md p-4">
      <header className="">
        <h3 className="pb-2 text-xs font-normal text-blue">
          {/* TODO - Update font */}
          LONELY PLANET’S RECOMMENDED eSIM
        </h3>
        <p className="pb-3 text-2xl font-bold leading-none">
          Stay connected in {title}
        </p>
        <div className="flex items-center gap-1 text-xs leading-none">
          with
          {/* TODO - Improve sharpeness of this logo */}
          <SailyLogo className="w-[44px] h-[18px]" />
        </div>
        <p className="pt-4 pb-8">
          Saily is an affordable eSIM service that helps you stay connected and
          secure, anywhere in the world.
        </p>
      </header>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href={url}
        className="block btn btn-primary w-content"
        target="_blank"
        rel="noopener nofollow"
        onClick={() => {
          tagular("click", {
            actionOutcome: "EXTERNALLINK",
            outboundUrl: url,
            webElement: {
              location: "PLANNINGDRIVER",
              elementType: "BUTTON",
              position: "0",
              text: "GET YOUR ESIM",
              name: "DESTINATIONHUB",
            },
          });
        }}
      >
        Get your eSIM
      </a>
    </div>
  );
}

Saily.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
